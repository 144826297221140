import React from "react";
import "../css/formBase.css"
import ParticleBackground from "./particleBackground";

const FormBase = (props) => {
    return (
        <div style={{position: "absolute", left: "23%", top: "15%"}}>
          <div className="FormBaseScreen">
            {props.formContent}
        </div>
        <ParticleBackground/>
      </div>
    );
}

export default FormBase;
