import React from "react";
import "../css/login.css"
import logobg from "../imgs/logobg.png"
import floatGuy from "../imgs/float-guy.png"
import imageOne from "../imgs/login-1.png"
import imagetwo from "../imgs/login-2.png"
import imagethree from "../imgs/login-4.png"
import ParticleBackground from "./particleBackground";
import { json, Link, Navigate } from "react-router-dom";
import { useState } from "react";
import apiRequest from "../js/api.js";

const Login = (props) => {
  const [loggedin, changeloggedIn] = useState(false);
  const [errorMessage, AddErrorMessage] = useState("");
  
  const tryLogin = (data) => {

    let newData = {
    identifier: document.getElementById("phone").value,
    password: document.getElementById("pword").value,
    provider: 1 
    }

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }

    let didLogin = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Login`,"POST", headers,newData)
      didLogin.then((results) => 
        {
        switch(results.status){
          case "500-Internal Server Error":
            AddErrorMessage("Failed to login")
            break;
          case "200-OK":
            //get data from token
            window.localStorage.setItem("porteiro_user_token",JSON.stringify(results.data));
            props.data.Auth.changeAuth(true);
            break;
          default:
            AddErrorMessage("Something went wrong please try again")
            break;
        }
      }); 
    }
  
  React.useEffect(() => {
    var counter = 2;
    setInterval(() => {
      switch(counter){
        case 1:
          document.getElementById(`FloatGuy3`).style.animation = "slideOut .5s linear 0s 1 forwards";
          break;
        case 2: 
          document.getElementById(`FloatGuy1`).style.animation = "slideOut .5s linear 0s 1 forwards";
            break;
        case 3:
          document.getElementById(`FloatGuy2`).style.animation = "slideOut .5s linear 0s 1 forwards";
            break;
      }
      document.getElementById(`FloatGuy${counter}`).style.removeProperty("animation-name")
      document.getElementById(`FloatGuy${counter}`).style.animation = "slideIn .5s linear 0s 1 forwards";
      counter++;
      if(counter > 3){
        counter = 1;
      }
    }, 10000)
  })

  return (
      <>
      <div className="WelcomeScreen">
        <div className="BackgroundContainer">
          <img alt="" style={{marginLeft: "-13%", marginTop: "37%"}} className="FloatGuy" id="FloatGuy1" width={"105%"} height={"80%"} src={imageOne}/>
          <img alt="" style={{marginLeft: "30%",marginTop: "50%", opacity: 0}} className="FloatGuy" id="FloatGuy2" width={"120%"} height={"75%"} src={imagetwo}/>
          <img alt="" style={{marginLeft: "5%",marginTop: "18%", opacity: 0}} className="FloatGuy" id="FloatGuy3" width={"135%"} height={"90%"} src={imagethree}/>
          <img style={{position: "absolute", zIndex: "0", width: "85%", opacity: "30%"}} src={logobg}/>
        </div>
        <div style={{width: "55%", height: "70%",marginLeft: "20px",display: "flex", flexDirection: "column", alignItems: "center"}}>
          <h3 style={{color: 'black',fontSize: "1.5rem",fontFamily: "Segoe UI", letterSpacing: "1px", marginBottom: "40px"}}>Welcome back!</h3>
          {errorMessage && (
            <p style={{color: "red", margin: "0px 0px 20px 0px"}}>{errorMessage}</p>
          )}
          <div onChange={() => AddErrorMessage("")} className="InputContainer"><input id={"phone"} className="Input" placeholder={"Phone"}></input></div>
          <div onChange={() => AddErrorMessage("")} className="InputContainer" style={{paddingBottom: "0px"}}><input id={"pword"} className="Input" placeholder={"Password"} type={"password"}></input></div>
          <div style={{fontSize: ".8rem", letterSpacing: "1px", marginBottom: "20px"}}>Dont have an account? Sign up, <Link style={{textDecoration: "none", color: "#8176FF"}} to={"/path"}>here</Link></div>
          <div style={{display: "flex",justifyContent: "center",width: "40%", margin: "10px"}}>
            <button onClick={() => tryLogin(props.data)} className="LoginButton">LOG IN</button>
          </div>
        </div>
        </div>
        <ParticleBackground/>
        </>
  );
}

export default Login;
