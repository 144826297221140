import React from "react";
import "../css/home.css"
import logo from "../imgs/logobg.png"
import phone from "../imgs/homePhone.png"
import circle from "../imgs/purplecircle.png"
import bubble from "../imgs/bubble.png"
import { Link } from "react-router-dom";

const Home = (props) => {

    return (
    <>
        <div className="Home">
            <img alt="" style={{position: "absolute",left: "10%", top: "0", width: "80%", height:"100%",zIndex: "-1"}} src={bubble}/>
            <div style={{width: "55%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", zIndex: "1"}}>
                <div style={{width: "90%", display: "flex", flexDirection: "column",alignItems: "center",margin: "50px"}}>
                    <img alt="The Porteiro logo" className="FadeInDiv" width={128} height={128} src={logo}/>
                    <h4 className="FadeInDiv" style={{letterSpacing: "10px", fontSize: "3rem", fontFamily: "Segoe UI", fontWeight: "bold", margin: "0px"}}>PORTEIRO</h4>
                    <p className="FadeInDivDelay" style={{color: "#FFFFFF9f", textAlign: "center",letterSpacing: "1px",marginTop: "5px",marginBottom: "5px"}}>THE SIMPLEST WAY TO FIND,<br/>BUY OR SELL A SERVICE.</p>
                    <div className="FadeInDivDelayMore" style={{display: "flex", flexDirection: "row", width: "50%", marginTop: "5%"}}>
                        <Link className="HomeButton" to={"login"} >LOG IN</Link>
                        <Link className="HomeButton" to={"path"} >SIGN UP</Link>
                    </div>
                </div>
            </div>
            <div style={{width: "40%", height: "100%",display: "flex"}}>
                <img alt="" className="FadeIn" style={{width: "90%", height: "100%", alignSelf: "center"}} src={phone}/>
                <img alt="" style={{width: "45%",height: "100%",position: "absolute", bottom: "0", left: "0"}} src={circle}/>
            </div>
        </div>
      </>
    );
}

export default Home;
