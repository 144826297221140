import React from "react";
import "../css/partner.css"
import FormBase from "./formBase";
import logo from "../imgs/logobg.png"
import { useState } from "react";
import { useRef } from "react";
import upload from '../imgs/upload.png'
import profile from '../imgs/profile.png'
import banner from '../imgs/banner.png'
import Service from "./service";
import { ImageUpload } from "./imageUpload";
import email from "../imgs/email.png";
import phone from "../imgs/phone2.png";
import { Link } from "react-router-dom";
import CheckMark from "./CheckMark";
import check from "../imgs/checkMark.svg";
import Button from "./button";
import { ProgressBar, Step } from "react-step-progress-bar";
import usStates from "./states.js";
import apiRequest from "./api";
import Multiselect from "multiselect-react-dropdown";

const Partner = (props) => {
    const ref = useRef(null);
    var currentData = {};
    currentData.services = [];
    var imgUrls = [];
    const validPassword = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");

    const handleChange = (nextStep) => {
        let valid = true;
        ref.current.childNodes.forEach(node => {
            //Checking to see if all three service selects are active
            //Finding correct way to parse user data based off input ID's
                switch(node.id){
                    case "":
                        break;
                    case "photos":
                    node.childNodes.forEach(photoNode => {
                        photoCheck:if(photoNode.id !== ""){
                            console.log(currentData[photoNode.id])
                            if(photoNode.validity.valid === false && currentData[photoNode.id] === undefined){
                                if(valid === true){alert("Please provide both photos")}
                                valid = false;
                                break photoCheck;
                            } else if(photoNode.value === ""){
                                break photoCheck;
                            }
                            console.log([photoNode.id, photoNode.files[0]])
                            currentData[photoNode.id] = photoNode.files[0];
                            if(photoNode.files.length > 0){
                                imgUrls.push(URL.createObjectURL(photoNode.files[0]))
                            }
                        }
                    })
                        break;
                    case "ServiceSelects":
                        node.childNodes.forEach(select => {
                            if(select.id === "ServiceSelectPriceContainer"){
                                if(select.childNodes[1].validity.valid === false){
                                    document.getElementById(select.childNodes[1].id).style.backgroundColor = "#FFCCCC";
                                    document.getElementById(select.childNodes[1].id).style.border = "#bd1616 2px solid";
                                    valid = false;
                                } else {
                                    currentData[select.childNodes[1].id] = select.childNodes[1].value 
                                }                
                            } else{
                                currentData[select.id] = select.value
                            }
                        })
                        break;
                    case "threeSelect":
                        break;
                    default: 
                        if(node.validity.valid === false){
                            document.getElementById(node.id).style.backgroundColor = "#FFCCCC";
                            document.getElementById(node.id).style.border = "#bd1616 2px solid";
                            if(node.id.includes("ServiceDescription")) {
                                document.getElementById(node.id).classList.remove("ServiceInput");
                                document.getElementById(node.id).classList.add("ServiceError");
                            }
                            valid = false;
                            break;
                        }
                        if(node.id === "password" || node.id === "confirmPassword"){
                            let test = validate();
                            if(test === false){document.getElementById(node.id).style.backgroundColor = "#FFCCCC"; document.getElementById(node.id).style.border = "#bd1616 2px solid"; valid = false; break;}
                        }
                        console.log([node.id,node.value]);
                        currentData[node.id] = node.value;
                        break;
                }
            });

        if(nextStep === "stepTen" && (currentData.services.length == 0 || currentData.services[currentData.services.length - 1].length == 0)){
            valid = false
        }

        if (valid === true && nextStep === "stepTen"){
            changeStep(<StepTen services={currentData.services[currentData.services.length - 1]}/>);
        } else if(valid === true){
            if (nextStep.props.name === "stepFive") {tryCreatePartner(nextStep);}
            else if (nextStep.props.name === "stepNine") {tryUpdatePartner(nextStep);}
            else if (nextStep.props.name === "stepEleven") {tryCreateServices(nextStep);}
            else {changeStep(nextStep);}
        }
        console.log(currentData);
    };

    const goBack =  (previousStep) => {
        changeStep(previousStep)
    }

    const tryLogin = (nextStep) => {
        let newData = {
        identifier: currentData.mobile,
        password: currentData.password,
        provider: 1 
        }
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
        let didLogin = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Login`,"POST", headers,newData)
            didLogin.then((results) => 
            {
            switch(results.status){
                case "500-Internal Server Error":
                    break;

                case "200-OK":
                    //get data from token
                    window.localStorage.setItem("porteiro_user_token",JSON.stringify(results.data));
                    props.data.Auth.changeAuth(true);
                    changeStep(nextStep);
                    break;

                default:
                    break;
                }
        }); 
    }
            
    const tryCreatePartner = (nextStep) => {
        let newData = {
        mobile: currentData.mobile,
        email: currentData.email,
        password: currentData.password,
        firstName: currentData.firstName,
        middleName: currentData.middleName,
        lastName: currentData.lastName,
        suffix: currentData.suffix === "Suffix" ? "" : currentData.suffix,
        driverLicenseNumber: currentData.driversLicenseNumber,
        driverLicenseIssueDate: currentData.driversLicenseIssueDate,
        driverLicenseExpirationDate: currentData.driversLicenseExpirationDate,
        dob: currentData.dob,
        address: currentData.address,
        city: currentData.city,
        state: currentData.state,
        zip: currentData.zip,
        businessName: currentData.businessName
        }

        let headers = {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
        let didCreatePartner = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Partner/Register`,"POST", headers,newData)
        didCreatePartner.then((results) => 
            {
            console.log(results);
            switch(results.status){
              case "500-Internal Server Error":
                let errorMessage = results.data == "Failed to create user account: Email or Mobile already in Use" ? "Email or Mobile already in Use" : "Something went wrong creating your account"
                changeStep(<StepOne errorMessage={errorMessage}/>);
                break;
              case "200-OK":
                console.log(results);
                currentData.partnerId = results.data.partnerId;
                tryLogin(nextStep);
                break;
              default:
                
                break;
            }
        }); 
    }
    
    const tryUpdatePartner = (nextStep) => {
        const userData = JSON.parse(window.localStorage.getItem("porteiro_user_token"));
        
        let newData = {
        profileImage: currentData.profilePhoto,
        backgroundImage: currentData.bannerPhoto,
        title: currentData.userTitle,
        about: currentData.userDescription,
        businessImage: currentData.businessLicensePhoto,
        userId: userData.user.id
        }

        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.bearer}`
        }

        let didUpdate = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Partner/Update`,"POST", headers,newData)
            didUpdate.then((results) => 
            {
            console.log(results);
    
            switch(results.status){
                case "500-Internal Server Error":
                    break;
    
                case "200-OK":
                    console.log(results);
                    changeStep(nextStep);
                    break;
    
                default:
                    break;
            }
        }); 
    }

    const tryGetServiceCategories = (serviceDataHolder) => {
        const userData = JSON.parse(window.localStorage.getItem("porteiro_user_token"));

        let headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization : `Bearer ${userData.bearer}`
        }

        let didGetServices = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Categories`,"GET", headers);

        didGetServices.then((results) => 
            {
            console.log(results);
            switch(results.status){

              case "500-Internal Server Error":
                return {}

              case "200-OK":
                console.log(results);
                return serviceDataHolder(results.data);

              default:
                return {}
            }
        });
    }

    const tryGetServiceSubCategories = (categoryId, subCategoriesUpdater) => {
        const userData = JSON.parse(window.localStorage.getItem("porteiro_user_token"));

        let headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization : `Bearer ${userData.bearer}`
        }

        let didGetServices = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Categories/${categoryId}/Children`,"GET", headers);

        didGetServices.then((results) => 
            {
            console.log(results);
            switch(results.status){

              case "500-Internal Server Error":
                return {}

              case "200-OK":
                let newCategories = []
                results.data.forEach(subCategory => {
                    newCategories.push({"value" : `${subCategory.id}:${categoryId}`, "text" : subCategory.name})
                });
                subCategoriesUpdater(newCategories);

              default:
                return {}
            }
        });
    }

    const tryCreateServices = (nextStep) => {
        const userData = JSON.parse(window.localStorage.getItem("porteiro_user_token"));

        let newData = {};
        let total = currentData.services.length;
        let i = 0;
        for(i = 0; i < total; i++){
            newData[i] = {
                "brief": currentData[`ServiceDescription${i}`],
                "cost": currentData[`ServiceSelectPrice${i}`],
                "duration" : currentData[`ServiceSelectTime${i}`],
                "categoryServiceId" : currentData.services[currentData.services.length - 1][i].value.split(":")[1],
                "subCategoryServiceId": currentData.services[currentData.services.length - 1][i].value.split(":")[0],
                "partnerId" : currentData.partnerId,
                "userId" : userData.user.id
            }
        }

        let headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization : `Bearer ${userData.bearer}`
        }
        let didCreateServices = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=PartnerServices/Create`,"POST", headers,newData)
        didCreateServices.then((results) => 
            {
            console.log(results);
            switch(results.status){
              case "500-Internal Server Error":
              
                break;
              case "200-OK":
                console.log(results);
                changeStep(nextStep);
                break;
              default:
                
                break;
            }
        }); 
    }

    function GetStates(){
        let states = [];
        usStates.map((state) => {
            states.push(<option value={state.name}>{state.abreviation}</option>)
        })
        return states;
    }


    const servicesInfo = () =>{
        let totalServices = [];
        let dataLength = currentData.services.length;
        console.log(dataLength);
        let i = 0;
        while(i < dataLength){
            totalServices.push(
                <div style={{display: "flex", flexDirection: "column", width: "100%",marginBottom: "2vh"}}>
                     <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                        <p style={{fontSize: "1.3rem",marginBottom: "10px"}} className="AccountText BoldSpaced">{currentData[`ServiceTitle${i}`]}</p>
                        <p style={{color: "#ffffffab"}} className="AccountText">{`$${currentData[`ServiceSelectPrice${i}`]}`}</p>
                     </div>
                     <p className="AccountText">{currentData[`ServiceDescription${i}`]}</p>
                </div>
            )
            i++;
        }
        return totalServices;
    }

    const handleServices = (newServices) => {
        let createdServices = []
        let temp = newServices.length;
        let i = 0;
        while(i < temp){
            createdServices.push(<Service serviceCategories={newServices[i].value} serviceNumber={i} serviceTitle={newServices[i].text} defaultDescription={currentData[`ServiceDescription${i}`]} defaultTime={currentData[`ServiceSelectTime${i}`]} defaultPrice={currentData[`ServiceSelectPrice${i}`]}/>)
            i++;
        }
        return createdServices;
    }

    const handleBackground = (e) => {
        if(e.target.validity.valid === true){
            e.target.style.background = "#ffffff";
            e.target.style.border = "none";
        }
    }

    const validate = () => {
        let password = document.getElementById("password").value;
        let confirmPassword = document.getElementById("confirmPassword").value;
        if(validPassword.test(password) && password === confirmPassword && (password !== '' && confirmPassword !== '')){
            return true;
        }
        return false;
    }

    var StepTwelve = () => {
        return(
        <div key={10} id="stepTen" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px", display: "flex", alignItems:"center", flexDirection: "column"}}>
           <CheckMark/>
           <p className="FadeInAnimation" style={{color: "white",fontSize: "2rem", fontWeight: "bold",marginBottom: "0px"}}>You did it!</p>
           <p style={{marginTop: "none", color: "#A3A3B2"}}>We'll email you when you're background check porcesses</p>
           <Link to={"/porteiro"} style={{textDecoration: "none",textAlign: "center"}} className="FormButton">Continue</Link>
        </div>)
    };

    var StepEleven = () => {
        return(
        <div key={10} id="stepTen" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px", display: "flex", alignItems:"center", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} width={"14%"} height={"50%"} src={logo}/>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px"}}>Account Overview</p>
            </div>
            <div style={{marginTop: "3vh",display: "flex", flexDirection: "column", alignItems: "flex-start", alignSelf: "flex-start"}}>
                <div style={{height: "30%",position: "relative", display: "flex", justifyContent: "center",marginBottom: "7vh"}}>
                    <img src={imgUrls[0]} style={{position: "absolute",bottom:"-20%",left: "12%",zIndex: "1",display: "flex", justifyContent: "center", alignItems: "center",borderRadius: "150px", width: "8vw", height: "16vh", color: "white", textAlign: "center",border: "5px solid white"}}/>
                    <img src={imgUrls[1]} style={{borderRadius: "20px", width: "35vw", height: "30vh", color: "white", textAlign: "center",zIndex: "0",marginLeft:"3vw"}}/>
                </div>
                <p style={{fontWeight: "600", letterSpacing: "1px",marginLeft: "5vw",marginTop: "10px"}} className="AccountText">{currentData.bussinessName}</p>
                <p className="AccountText" style={{marginLeft:"5vw",marginTop: "10px"}}>{currentData.firstName} {currentData.lastName}, {currentData.userTitle}</p>
                <p className="AccountText" style={{marginLeft:"5vw",marginTop: "10px"}}>{currentData.address}, {currentData.city} {currentData.state}, {currentData.zip}</p>
                <p style={{marginTop: "15px",textAlign: "center", display: "flex", flexDirection: "row", alignItems: "flex-end",marginLeft: "5vw"}} className="AccountText"><img style={{width: "18px", height: "18px", marginRight: "7px"}} src={phone}/>912-123-4567 <img style={{width: "18px", height: "18px",marginLeft: "10px",marginRight: "7px"}} src={email}/>{currentData.email}</p>
            </div>
            <div style={{width: "110%", height: "2px", backgroundColor: "#8176FF",marginTop: "3vh"}}/>
            <div style={{width: "100%", display: "flex", flexDirection: "column",alignItems: "center",marginTop: "2vh"}}>
                {servicesInfo()}
            </div>
            <div ref={ref} style={{width: "100%", display: "flex", flexDirection: "column",alignItems: "center",marginTop: "2vh"}}>
                <div style={{width: "110%", height: "2px", backgroundColor: "#8176FF"}}/>
                <div style={{marginTop: "3vh",display: "flex",flexDirection: "row", justifyContent: "space-evenly"}}>
                    <div style={{width: "40%"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <p style={{marginRight: "10px"}} className="AccountText  BoldSpaced">Bundle Services</p>
                            <label className="switch">
                            <input type="checkbox"/>
                            <span className="slider round"/>
                            </label>
                        </div>
                        <p className="AccountText">Sell several services together as a single combined unit.</p>
                    </div>
                    <div style={{width: "40%"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <p style={{marginRight: "10px"}} className="AccountText  BoldSpaced">Subscription Service</p>
                            <label className="switch">
                            <input type="checkbox"/>
                            <span className="slider round"/>
                            </label>
                        </div>
                        <p className="AccountText">Sell recurring discounted services for loyal members.</p>
                        <div>
                            <p style={{marginTop: "20px"}} className="AccountText BoldSpaced">Frequency</p>
                            <div className="SubscriptionOptions">
                                <p className="AccountText">Every 2-4 weeks</p>
                                <p className="AccountText  BoldSpaced">$910</p>
                            </div>
                            <div className="SubscriptionOptions">
                                <p className="AccountText">Every 6-8 weeks</p>
                                <p className="AccountText  BoldSpaced">$560</p>
                            </div>
                            <div className="SubscriptionOptions">
                                <p className="AccountText">Antime<br/>(12 visits/yr)</p>
                                <p className="AccountText  BoldSpaced">$925</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ButtonContainer">
                    <Button OnClick={() => {goBack(<StepTen services={currentData.threeSelect}/>)}} Text="Back"/>
                    <Button OnClick={() => {handleChange(<StepTwelve name="stepTwelve"/>)}} Text="Looks Great!"/>
                </div>
            </div>
        </div>)
    };

    var StepTen = (props) => {
        let allServices = handleServices(props.services)

        return(
        <div key={10} id="stepTen" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px", display: "flex", alignItems:"center", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={79} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                10
                                </div>
                                )}
                            </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px", textAlign: "center"}}>Tell us a little bit more about your<br/> services:</p>
            </div>
            <div style={{marginTop: "3vh",width: "110%", height: "2px", backgroundColor: "#8176FF"}}/>
            <div ref={ref} style={{width: "100%", display: "flex", flexDirection: "column"}}>
                {allServices}
                <div style={{alignSelf: "center"}} className="ButtonContainer">
                    <Button customStyle={{width: "100%"}} OnClick={() => {handleChange(<StepEleven name="stepEleven"/>)}} Text="Next"/>
                </div>
            </div>
        </div>)
    };

    var StepNine = (props) => {
        const [services, updateServices] = useState();
        const [subServices, updateSubServices] = useState();
        React.useEffect(() => {
            tryGetServiceCategories(updateServices);
        }, [])

        return(
        <div key={9} id="stepNine" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={47} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                9
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                10
                                </div>
                                )}
                            </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px"}}>Please select one answer below for the category of service you offer</p>
            </div>
            <div id="selectHolder" ref={ref} style={{display: "flex", flexDirection: "column", width: "100%", height: "60%", justifyContent:"space-between",alignItems: "center"}}>
                <select id="mainSelect" onChange={() => tryGetServiceSubCategories(document.getElementById("mainSelect").value, updateSubServices)} className="FormInputSelect" defaultValue={10}>    
                    {services && (services.map(service => (
                        <option key={service.id} value={service.id}>{service.name}</option>
                    )))}
                </select>
                {subServices && (<Multiselect options={subServices} className="MultiFormInput"  displayValue="text" onSelect={(selectedList) => {currentData.services.push(selectedList)}} id={"threeSelect"}/>)}
                <div className="ButtonContainer">
                    <Button OnClick={() => {goBack(<StepEight name="stepEight"/>)}} Text="Back"/>
                    <Button OnClick={() => {handleChange("stepTen")}} Text="Next"/>
                </div>
            </div>
        </div>)
    };

    var StepEight = () => {
        return(
        <div key={8} id="stepEight" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%", marginBottom: "30px"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={13} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                8
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                9
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                10
                                </div>
                                )}
                            </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px",textAlign: "center"}}>Please upload a photo of your<br/> bussiness license</p>
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "row",flexWrap: "wrap", width: "100%", height: "60%", justifyContent:"center",alignItems: "center"}}>
                <div id="photos" style={{width: "98%",height: "30%",position: "relative", display: "flex", justifyContent: "center"}}>
                    <label name="businessLicenseLabel" style={{display: "flex", justifyContent: "center", alignItems: "center",backgroundColor: "grey", borderRadius: "20px", width: "30vw", height: "22vh", color: "white", textAlign: "center",zIndex: "0",padding: "10px"}} htmlFor="businessLicensePhoto"><img alt="An arrow to signify uploading" width="60px" height="60px" src={upload}/></label>
                    <ImageUpload defaultPreview={currentData.businessLicensePhoto} inputId={"businessLicensePhoto"} imageStyle={{position: "absolute",borderRadius: "20px", width: "32vw", height: "25vh", color: "white", textAlign: "center",zIndex: "0"}}/>
                </div>
                <div className="ButtonContainer">
                    <Button customStyle={{marginLeft: "-15px", width: "35%"}} OnClick={() => {goBack(<StepSix name="stepSix"/>)}} Text="Back"/>
                    <Button customStyle={{marginRight: "-15px",width: "35%"}} OnClick={() => {handleChange(<StepNine name="stepNine"/>)}} Text="Next"/>
                </div>
            </div>
        </div>)
    };

    var StepSeven = () => {
        return(
        <div key={7} id="stepSeven" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={79} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                7
                                </div>
                                )}
                            </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px"}}>Where can customers find you?</p>
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "column", width: "100%", height: "60%", justifyContent:"space-between",alignItems: "center"}}>
                <input className="FormInput" id="bussinessAddress" type={"text"} placeholder={"Street Address*"} defaultValue={currentData.bussinessAddress} onChange={handleBackground} required={true}/> 
                <input className="FormInput" id="bussinessCity" type={"text"} placeholder={"City, State*"} defaultValue={currentData.bussinessCity} onChange={handleBackground} required={true}/> 
                <input className="FormInput" id="bussinessZip" type={"text"} placeholder={"Zip*"} defaultValue={currentData.bussinessZip} onChange={handleBackground} required={true}/> 
                <div className="ButtonContainer">
                    <Button customStyle={{marginLeft: "-15px", width: "35%"}} OnClick={() => {goBack(<StepSix/>)}} Text="Back"/>
                    <Button customStyle={{marginRight: "-15px",width: "35%"}} OnClick={() => {handleChange(<StepEight/>)}} Text="Next"/>
                </div>
            </div>
        </div>)
    };

    var StepSix = () => {
        return(
        <div key={6} id="stepSix" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={47} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                6
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                7
                                </div>
                                )}
                            </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px"}}>Tell everyone a little more about yourself</p>
                <p style={{color: "#FFFFFF7f", fontSize: ".9vw"}}>Remember your future clients will see this information!</p>
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "column", width: "100%", height: "60%", justifyContent:"space-between",alignItems: "center"}}>
                <input className="FormInput" id="userTitle" type={"text"} placeholder={"Your Title (Ex, Hair Stylist)*"} defaultValue={currentData.userTitle} onChange={handleBackground} required={true}/> 
                <textarea maxLength={255} className="FormInput" id="userDescription" type="text" style={{resize: "none", height: "15vh"}} placeholder={"Tell us a bit about yourself"} defaultValue={currentData.userDescription}/> 
                <div className="ButtonContainer">
                    <Button customStyle={{marginLeft: "-15px", width: "35%"}} OnClick={() => {goBack(<StepFive firstName={currentData.firstName} name="stepFive"/>)}} Text="Back"/>
                    <Button customStyle={{marginRight: "-15px",width: "35%"}} OnClick={() => {handleChange(<StepEight name="stepEight"/>)}} Text="Next"/>
                </div>
            </div>
        </div>)
    };

    const StepFive = (props) => {
        return (
        <div key={5} id="stepFive" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={13} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                5
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                6
                                </div>
                                )}
                            </Step>
                            <Step>{() => (
                                <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                7
                                </div>
                                )}
                            </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px",textAlign: "center"}}>Welcome {props.firstName}!<br/>Lets customize your account</p>
                <p style={{color: "#FFFFFF7f", fontSize: ".9vw", textAlign: "center"}}>upload a profile picture and banner</p>
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "row",flexWrap: "wrap", width: "100%", height: "60%", justifyContent:"center",alignItems: "center"}}>
                <div id="photos" style={{width: "98%",height: "30%",position: "relative", display: "flex", justifyContent: "center"}}>
                    <label name="profileLabel" style={{cursor: "pointer",position: "absolute",top:"5%",left: "12%",zIndex: "3",display: "flex", justifyContent: "center", alignItems: "center",backgroundColor: "#e1d4d4", borderRadius: "150px", width: "11vw", height: "22vh", color: "white", textAlign: "center"}} htmlFor="profilePhoto"><img alt="An arrow to signify uploading" width="60px" height="60px" src={profile}/></label>
                    <ImageUpload defaultPreview={currentData.profilePhoto} inputId={"profilePhoto"} imageStyle={{position: "absolute",top:"5%",left: "12%",zIndex: "2",display: "flex", justifyContent: "center", alignItems: "center",borderRadius: "150px", width: "11vw", height: "22vh", color: "white", textAlign: "center"}}/>
                    <label name="bannerLabel" style={{cursor: "pointer",display: "flex", justifyContent: "flex-end", alignItems: "center",backgroundColor: "grey", borderRadius: "20px", width: "30vw", height: "22vh", color: "white", textAlign: "center",zIndex: "1",padding: "10px"}} htmlFor="bannerPhoto"><img alt="An arrow to signify uploading" style={{marginRight: "4vw"}} width="60px" height="60px" src={banner}/></label>
                    <ImageUpload defaultPreview={currentData.bannerPhoto} inputId={"bannerPhoto"} imageStyle={{position: "absolute",borderRadius: "20px", width: "32vw", height: "25vh", color: "white", textAlign: "center",zIndex: "0"}}/>
                </div>
                <div className="ButtonContainer">
                    <Button customStyle={{width: "100%"}} OnClick={() => {handleChange(<StepSix name="stepSix"/>)}} Text="Next"/>
                </div>
            </div>
        </div>
    )};

    var StepFour = () => { 
        return(
        <div key={4} id="stepFour" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={79} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                            </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                4
                            </div>
                            )}
                        </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px",textAlign: "center"}}>Background Check</p>
                <p style={{color: "#FFFFFF7f", fontSize: ".9vw", textAlign: "center"}}>*The following information is required to establish your identity<br/> and conduct a criminal background check by our risk<br/>managment partner, Global Verification Network (GVN).</p>
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "row",flexWrap: "wrap", width: "100%", height: "60%", justifyContent:"center",alignItems: "center"}}>
                <input className="FormInputBGC" id="dob" type={"date"} placeholder={"Date of Birth*"} defaultValue={currentData.dob} onChange={handleBackground} required={true}/> 
                <input className="FormInputBGC" id="driversLicenseNumber" type={"password"} placeholder={"Drivers License Number*"} defaultValue={currentData.driversLicenseNumber} onChange={handleBackground} required={true}/> 
                <input className="FormInputBGC" id="driversLicenseIssueDate" type={"text"} placeholder={"Drivers License Issue Date*"} defaultValue={currentData.driversLicenseIssueDate} onChange={handleBackground} required={true}/>
                <input className="FormInputBGC" id="driversLicenseExpirationDate" type={"text"} placeholder={"Drivers License Expiration Date*"} defaultValue={currentData.driversLicenseExpirationDate}  onChange={handleBackground} required={true}/> 
                <div className="ButtonContainer">
                    <Button customStyle={{marginLeft: "-15px", width: "35%"}} OnClick={() => {goBack(<StepThree name="stepThree"/>)}} Text="Back"/>
                    <Button customStyle={{marginRight: "-15px",width: "35%"}} OnClick={() => {handleChange(<StepFive firstName={currentData.firstName} name="stepFive"/>)}} Text="Next"/>
                </div>
            </div>
        </div>)
    };

    var StepThree = () => { 
        return(
        <div key={3} id="stepThree" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={47} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                3
                            </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                4
                            </div>
                            )}
                        </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px",textAlign: "center"}}>Where is your primary residence?</p>
                <p style={{color: "#FFFFFF7f", fontSize: ".9vw", textAlign: "center"}}>You'll have to preform a background check don't worry you can still continue<br/> to set up an account while it is processing</p>
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "row",flexWrap: "wrap", width: "100%", height: "60%", justifyContent:"center",alignItems: "center"}}>
                <input className="FormInput" id="businessName" type={"text"} placeholder={"Business Name*"} defaultValue={currentData.businessName} onChange={handleBackground} required={true}/> 
                <input className="FormInput" id="address" type={"text"} placeholder={"Address*"} defaultValue={currentData.address} onChange={handleBackground} required={true}/>
                <input style={{width: "33%"}} className="FormInput" id="city" type={"text"} placeholder={"City*"} defaultValue={currentData.city} onChange={handleBackground} required={true}/> 
                <div style={{width: "2%"}}/>
                <select style={{width: "11%"}} className="FormInput" id="state" type={"text"} placeholder={"State*"} defaultValue={currentData.state} required={true}>{GetStates()}</select> 
                <div style={{width: "2%"}}/>
                <input className="FormInput" id="zip" placeholder="Zip*" style={{width: "8%"}} defaultValue={currentData.zip} onChange={handleBackground} required={true}/>
                <div className="ButtonContainer">
                    <Button customStyle={{marginLeft: "-15px", width: "35%"}} OnClick={() => {goBack(<StepTwo name="stepTwo"/>)}} Text="Back"/>
                    <Button customStyle={{marginRight: "-15px",width: "35%"}} OnClick={() => {handleChange(<StepFour name="stepFour"/>)}} Text="Next"/>
                </div>
            </div>
        </div>)
    };

    var StepTwo = () => {
        return(
        <div key={2} id="stepTwo" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={13} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             2
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                3
                            </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                4
                            </div>
                            )}
                        </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px",textAlign: "center"}}>At Porteiro security is important</p>
                <p style={{color: "#FFFFFF7f", fontSize: ".9vw", textAlign: "center"}}>You'll have to preform a background check don't worry you can still continue<br/> to set up an account while it is processing</p>
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "row",flexWrap: "wrap", width: "100%", height: "60%", justifyContent:"center",alignItems: "center"}}>
                <input className="FormInput" id="firstName" type={"text"} placeholder={"First Name*"} defaultValue={currentData.firstName} onChange={handleBackground} required={true}/> 
                <input className="FormInput" id="middleName" type={"text"} placeholder={"Middle Name*"} defaultValue={currentData.middleName} required={true}/>
                <input style={{width: "42%"}} className="FormInput" id="lastName" type={"text"} placeholder={"Last Name*"} defaultValue={currentData.lastName} onChange={handleBackground} required={true}/> 
                <div style={{width: "3%"}}/>
                <select id="suffix" placeholder="Suffix" defaultValue={currentData.suffix === undefined? "Suffix" : currentData.suffix} style={{color: "#000000af",outline: "none",flexDirection: "row",width: "15%", padding: "14px", borderRadius: "3px", marginTop: "30px"}}>
                    <option hidden={true} disabled={true}>Suffix</option>
                    <option value={"Mr"} className="OptionText">Mr</option>
                    <option value={"Mrs"} className="OptionText">Mrs</option>
                    <option value={"Miss"}className="OptionText">Miss</option>
                    <option value={"Ms"} className="OptionText">Ms</option>
                    <option value={"Dr"} className="OptionText">Dr</option>
                    <option value={"Jr"} className="OptionText">Jr</option>
                    <option value={"Sr"} className="OptionText">Sr</option>
                </select>
                <div className="ButtonContainer">
                    <Button customStyle={{marginLeft: "-15px", width: "35%"}} OnClick={() => {goBack(<StepOne name="stepOne"/>)}} Text="Back"/>
                    <Button customStyle={{marginRight: "-15px",width: "35%"}} OnClick={() => {handleChange(<StepThree name="stepThree"/>)}} Text="Next"/>
                </div>
            </div>
        </div>)
    };

    var StepOne = (props) => {
        return(
        <div key={1} id="stepOne" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={0} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             1
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "27%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             2
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "56%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                3
                            </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                4
                            </div>
                            )}
                        </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px"}}>Welcome! First things first...</p>
                <p style={{color: "#FFFFFF7f", fontSize: ".9vw"}}>You can always change them later.</p>
                {props.errorMessage && (<p style={{color: "#e53131", fontSize: "1vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px"}}>{props.errorMessage}</p>)}
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "column", width: "100%", height: "60%", justifyContent:"space-between",alignItems: "center"}}>
                <input className="FormInput" id="mobile" onChange={handleBackground} type={"text"} placeholder={"Mobile*"} defaultValue={currentData.mobile} required={true}/> 
                <input className="FormInput" id="email" onChange={handleBackground} type={"email"} placeholder={"Email*"} defaultValue={currentData.email} required={true}/> 
                <input className="FormInput" id="password" onChange={handleBackground} type={"password"} placeholder={"Password* (Requires a Letter, Number, and Special Character)"} defaultValue={currentData.password} required={true}/> 
                <input className="FormInput" id="confirmPassword" onChange={handleBackground} type={"password"} placeholder={"Confirm Password*"} defaultValue={currentData.confirmPassword} required={true}/> 
                <Button customStyle={{width: "60%"}} OnClick={() => {handleChange(<StepTwo name="stepTwo"/>)}} Text="Next"/>
            </div>
        </div>)
    };

    const [step, changeStep] = useState(<StepOne/>);
    
    return (
        <>
          <FormBase formContent={step}/>
        </>
    );
}
export default Partner;