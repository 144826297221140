import React from "react";
import "../css/path.css"
import ParticleBackground from "./particleBackground";
import headshot from "../imgs/headshot.png"
import hearthand from "../imgs/hearthand.png"
import { Link } from "react-router-dom";

const Path = () => {

    return (
        <>
          <div className="PathScreen">
            <div style={{marginBottom: "5%",width: "100%", height: "30%", display: "flex", flexDirection: "column",justifyContent: "center",alignItems: "center", color: "white", fontSize: "1.5rem", letterSpacing: "1px"}}>
                <h3>How are you planning to use PORTEIRO?</h3>
                <p style={{margin: "0px", fontSize: ".8rem"}}>We'll streamline your setup experience accordingly.</p>
            </div>
            <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Link className="PathOption" to="member">
                        <img style={{width: "23%", height: "24%", marginLeft: "8%"}} src={headshot}/>
                        <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", letterSpacing: "1px", margin: "5% 0% 0% 8%"}}>Member</p>
                        <p style={{color: "white", marginLeft: "8%", fontSize: ".9vw",width: "75%"}}><strong>Busy Professionals and Individuals</strong> who want to save effort and time, “Simplifying Your Lifestyle” of finding and buying personalized services.</p>
                </Link>
                <Link className="PathOption" to="partner">
                    <img style={{width: "23%", height: "24%", marginLeft: "8%"}} src={hearthand}/>
                        <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", letterSpacing: "1px", margin: "5% 0% 0% 8%"}}>Partner</p>
                        <p style={{color: "white", marginLeft: "8%", fontSize: ".9vw",width: "75%"}}><strong>Solopreneurs and Small Business owners</strong> who provide mobile personal care services and want to save money, “Maximizing Your Business Value” of selling services.</p>
                </Link>
            </div>
        </div>
        <ParticleBackground/>
      </>
    );
}

export default Path;